import { Close, HelpOutline } from "@mui/icons-material";
import { IconButton, Box, Button, Dialog, DialogContent, DialogTitle, Typography, Skeleton } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { QuestionarioAPI } from "src/api/questionarioApi";
import { FaqObj } from "src/types/questionario.types";

interface Props {
    idVolume: number;
}

export default function FaqVolume(props: Props) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [listFaq, setListFaq] = useState<FaqObj[]>([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        if (props.idVolume)
            getFaqList();
    }, [props.idVolume])

    async function getFaqList() {
        setIsLoading(true);
        try {
            const result = await QuestionarioAPI.getFaq({ idVolume: props.idVolume });
            setListFaq([...result.data]);
        } catch (error) {
            setListFaq([]);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            {isLoading ? (
                <Skeleton variant="rounded" width="40" />
            ) : (
                listFaq.length > 0 ? (
                    <Button size="large" onClick={() => setIsDialogOpen(true)} variant="outlined" startIcon={<HelpOutline />}>
                        FAQ
                    </Button>
                ) : (<span></span>)
            )}
            <Dialog fullWidth maxWidth="md" open={isDialogOpen}>
                <DialogTitle>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h6" component="span">
                            FAQ
                        </Typography>
                        <IconButton onClick={() => setIsDialogOpen(false)} edge="end" aria-label="close">
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {listFaq.map((faq) => (
                        <Box key={faq.id} sx={{mb:3}}>
                            <Typography variant="h6">{faq.domanda}</Typography>
                            <Typography>{faq.risposta}</Typography>
                        </Box>
                    )
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}
