import { Box, Button, IconButton, Skeleton, Tooltip } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { SocialAPI } from "../../api/socialApi";
import { BadgeObj } from "../../types/questionario.types";
import { WorkspacePremium, WorkspacePremiumOutlined } from "@mui/icons-material";
import Classifica from "../classifica/classifica";
import Forum from "../forum/forum";

export default function ButtonsAndAwards(props: Props) {
    const [badgesList, setBadgesList] = useState<BadgeObj[]>([]);
    const [areBadgesLoaded, setAreBadgesLoaded] = useState(false);
    const [tooltips, setTooltips] = useState<Record<number, boolean>>({});

    useEffect(() => {
        if (props.idQuestionario && props.idQuestionario !== -1) {
            getBadgesList();
        }
    }, [props.idQuestionario]);

    async function getBadgesList() {
        try {
            const result = await SocialAPI.getBadges({ idQuestionario: props.idQuestionario });
            if (result.success) {
                setBadgesList([...result.data]);
                const initialTooltipsState = result.data.reduce((acc, badge) => {
                    acc[badge.id] = false;
                    return acc;
                }, {} as Record<number, boolean>);
                setTooltips(initialTooltipsState);
            } else throw new Error("Errore durante il recupero dei badge");
        } catch (error) {
            setBadgesList([]);
        } finally {
            setAreBadgesLoaded(true);
        }
    }

    function handleToggleTooltip(id: number): void {
        setTooltips((prev) => {
            const allClosed = Object.keys(prev).reduce((acc, key) => {
                acc[Number(key)] = false;
                return acc;
            }, {} as Record<number, boolean>);

            allClosed[id] = !prev[id];
            return allClosed;
        });
    }

    function handleCloseAllTooltips(): void {
        setTooltips((prev) => {
            const allClosed = Object.keys(prev).reduce((acc, key) => {
                acc[Number(key)] = false;
                return acc;
            }, {} as Record<number, boolean>);
            return allClosed;
        });
    }

    useEffect(() => {
        const handleClickOutside = () => {
            handleCloseAllTooltips(); // Chiude tutti i tooltip
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 3 }}>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "center" }}>
                <Button
                    variant="outlined"
                    onClick={props.openRiepilogoDialog}
                    sx={{ mt: { xs: 1, md: 0 }, width: { xs: "100%", md: "fit-content" } }}
                >
                    Mostra il riepilogo dei test svolti
                </Button>
                <Fragment>
                    <Classifica avviaSimulazione={props.avviSimulazione} idQuestionario={props.idQuestionario} />
                    {props.socialEnabled && (
                        <Forum idQuestionario={props.idQuestionario} />
                    )}
                </Fragment>
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'row' }, alignItems: "center" }}>
                {areBadgesLoaded ? (
                    badgesList.length > 0 && (
                        badgesList.map((badge) => (
                            !badge.isBadgeDisabled && (
                                <Tooltip
                                    placement="bottom-end"
                                    key={badge.id}
                                    open={tooltips[badge.id] || false}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={`${badge.achieved ? "" : "NON"} OTTENUTO - ${badge.nomeBadge}`}
                                    arrow
                                    slotProps={{
                                        popper: {
                                            disablePortal: true,
                                        },
                                    }}
                                >
                                    <IconButton
                                        color={badge.achieved ? "primary" : "default"}
                                        sx={{ ml: 1 }}
                                        onClick={(e) => {
                                            e.stopPropagation(); // Evita la chiusura quando clicchi sul badge
                                            handleToggleTooltip(badge.id);
                                        }}
                                    >
                                        {!badge.achieved ? (
                                            <img
                                                src={process.env.REACT_APP_IMAGES_PREFIX + "static/" + badge.img}
                                                alt="Badge Inactive"
                                                style={{ opacity: badge.achieved ? 1 : 0.70, width: '36px', height: '36px' }}
                                            />
                                        ) : (
                                            <img
                                                src={process.env.REACT_APP_IMAGES_PREFIX + "static/" + badge.img}

                                                alt="Badge Active"
                                                style={{ width: '36px', height: '36px' }}
                                            />
                                        )}
                                    </IconButton>

                                </Tooltip>
                            )
                        ))
                    )
                ) : (
                    <Fragment>
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="circular" width={40} height={40} sx={{ mx: 1 }} />
                        <Skeleton variant="circular" width={40} height={40} />
                    </Fragment>
                )}
            </Box>
        </Box>
    );
}

interface Props {
    socialEnabled?: boolean;
    idQuestionario: number;
    openRiepilogoDialog: () => void;
    avviSimulazione: () => void;
}
