import PieGraph from "../pieGraph"
import Forum from "../forum/forum"
import { ArrowForward, ArrowRight, Check, Close, Done } from "@mui/icons-material"
import { Avatar, Box, Button, Divider, Grid, Paper, Typography } from "@mui/material"
import { blue, green, red } from "@mui/material/colors"
import { Fragment } from "react/jsx-runtime"

export default function EsitoAndGrafico(props: Props) {
    return (
        <Fragment>
            {props.typeQuestionario === "Sim" && (
                <Paper elevation={4} sx={{ py: 5 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        {props.esito === true ? (
                            <Avatar sx={{ bgcolor: green[800], width: 60, height: 60 }}><Done /></Avatar>
                        ) : (
                            <Avatar sx={{ bgcolor: red[600], width: 60, height: 60 }}><Close /></Avatar>
                        )}
                        <Box sx={{ ml: 5 }}>
                            <Typography variant="body2">Esito simulatore:</Typography>
                            {props.esito === true ? (
                                <Typography variant="h3" sx={{ color: green[800] }}>Superato</Typography>
                            ) : (
                                <Typography variant="h3" sx={{ color: red[600] }}>Non Superato</Typography>
                            )}
                        </Box>
                    </Box>
                </Paper>
            )}

            <Paper elevation={4} sx={{ mt: 2 }}>
                <Box sx={{ display: "flex", flexDirection:{xs:'column',md:'row'}, alignItems: "center", justifyContent: "center", py: 3 }}>
                    {props.typeQuestionario === "Sim" && (
                        <Typography textAlign={"center"} sx={{ mr: 2,mb:{xs:1,md:0} }} variant="body1">Punteggio totale: <b style={{ color: '#003370' }}>{props.punteggioTotale}</b></Typography>
                    )}
                    <Typography textAlign={"center"} variant="body1">Tempo di completamento: <b style={{ color: '#003370' }}>{props.tempoCompletamento}</b></Typography>
                </Box>
                <Divider />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <PieGraph corrette={props.risposteCorrette} errate={props.risposteErrate} omesse={props.risposteOmesse} />
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p:{xs:5,md:0}, pr: {xs:5,md:3},height:'100%' }}>
                            <Box sx={{ display: "flex",  alignItems: "center" }}>
                                <Avatar sx={{ bgcolor: green[800], mr: 2 }}><Check /></Avatar>
                                <Typography variant="body2" sx={{ textAlign: 'center' }}>Risposte corrette: </Typography>
                                <Typography variant="body2" ><b>{props.risposteCorrette}</b></Typography>
                            </Box>
                            <Box sx={{ display: "flex",  alignItems: "center", mt: 2 }}>
                                <Avatar sx={{ bgcolor: red[600], mr: 2 }}><Close /></Avatar>
                                <Typography variant="body2" sx={{ textAlign: 'center' }}>Risposte errate: </Typography>
                                <Typography variant="body2" ><b>{props.risposteErrate}</b></Typography>
                            </Box>
                            <Box sx={{ display: "flex",  alignItems: "center", mt: 2 }}>
                                <Avatar sx={{ bgcolor: blue[700], mr: 2 }}><Close /></Avatar>
                                <Typography variant="body2" sx={{ textAlign: 'center' }}>Risposte omesse: </Typography>
                                <Typography variant="body2" ><b>{props.risposteOmesse}</b></Typography>
                            </Box>
                            <Button sx={{ my: 2 }} fullWidth onClick={props.showRiepilogoDomande} variant="outlined" endIcon={<ArrowForward />}>Vedi riepilogo domande</Button>
                            {(!props.isQuestionarioGratuito && props.socialFunctionsEnabled ) && (
                                <Forum isBtnFullWidth idQuestionario={parseInt(props.idQuestionario)} />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Fragment>
    )
}

interface Props {
    socialFunctionsEnabled:boolean;
    showRiepilogoDomande: () => void
    idQuestionario: string
    risposteErrate: number,
    risposteCorrette: number,
    risposteOmesse: number,
    tempoCompletamento: string
    punteggioTotale: number
    esito: boolean
    typeQuestionario: string
    isQuestionarioGratuito: boolean
}