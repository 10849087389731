import { DateTime, Settings } from 'luxon';

Settings.defaultLocale = 'it';
export function formatUtcInFormatoLeggibile(utcDate: string | undefined, showOra?: boolean): string {
    if (utcDate) {
        const date = DateTime.fromISO(utcDate, { zone: 'utc' });
        const italianDate = date.setZone('Europe/Rome');
        if (showOra)
            return italianDate.toFormat("dd MMMM yyyy - HH:mm");
        else return italianDate.toFormat("dd MMMM yyyy");
    } else return "";
}

